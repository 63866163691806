import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import theme from "../../styles/theme"; // Якщо потрібно використати тему

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${(props) => props.color};

  &:hover {
    color: ${(props) => props.hoverColor || theme.colors.white} !important;
  }
`;

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-left: 32px;
  padding-top: 4px;
`;

const Title = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-left: 24px;
`;

const socialMediaColors = {
  youtube: {
    color: "#ffff",
    hoverColor: "#CC0000",
  },
};

const SocialMediaLinks = ({ socialNetworks }) => {
  if (!socialNetworks || socialNetworks.length === 0) return null;

  return (
    <SocialMediaContainer>
      <Title>Соц. мережі</Title>
      <SocialMedia>
        {socialNetworks.some((network) => network.identifier === "youtube") && (
          <a
            target="blank"
            href={
              socialNetworks.find((network) => network.identifier === "youtube")
                .uri
            }
          >
            <StyledFontAwesomeIcon
              icon={faYoutube}
              color={socialMediaColors.youtube.color}
              hoverColor={socialMediaColors.youtube.hoverColor}
            />
          </a>
        )}
      </SocialMedia>
    </SocialMediaContainer>
  );
};

export default SocialMediaLinks;
