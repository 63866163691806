import React from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faYoutube,
  faTwitch,
} from "@fortawesome/free-brands-svg-icons";

import VladLuganskiyImage from "../../assets/SlickTeam/Luganskiy.png";
import AndriiDudaImage from "../../assets/SlickTeam/AndriiDuda.png";
import SerhiiKrasnokustkiiImage from "../../assets/SlickTeam/SerhiiKrasnokustkii.jpg";
import VladyslavTofetsky from "../../assets/SlickTeam/VladyslavTofetsky.jpg";

const FoundersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
`;

const FoundersTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

const FoundersTitle = styled.div`
  font-size: 20px;
  font-family: "Montserrat", sans-serif;sans-serif;
  font-weight: 400;
  color: ${theme.colors.white};
`;

const FoundersLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const StyledImgSection3 = styled.img`
  max-width: 150px;
  width: 100%;
  opacity: 1;
  height: 150px;
  object-fit: cover;
  border-radius: 150px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${(props) => props.color};

  &:hover {
    color: ${(props) => props.hoverColor || theme.colors.white} !important;
  }
`;

export const TeamData = [
  {
    image: AndriiDudaImage,
    name: "Андрій Дуда",
    biography: "Телеграм",
    socialMedia: [
      {
        icon: faInstagram,
        color: theme.colors.green,
        hoverColor: "#fd5949",
        link: "https://www.instagram.com/andriiduda471/",
      },
      {
        icon: faTwitch,
        color: theme.colors.green,
        hoverColor: "#6441a5",
        link: "https://www.twitch.tv/andron471",
      },
      {
        icon: faYoutube,
        color: theme.colors.green,
        hoverColor: "#ff0000",
        link: "https://www.youtube.com/channel/UCyJooSDqvXz54Aw1BJBVMPw",
      },
    ],
  },
  {
    image: VladLuganskiyImage,
    name: "Владислав Луганський",
    biography: "Телеграм",
    socialMedia: [
      {
        icon: faInstagram,
        color: theme.colors.green,
        hoverColor: "#fd5949",
        link: "https://www.instagram.com/vladluganskij2007",
      },
    ],
  },
  {
    image: SerhiiKrasnokustkiiImage,
    name: "Сергій Краснокутський",
    biography: "Рефері",
    socialMedia: [
      {
        icon: faInstagram,
        color: theme.colors.green,
        hoverColor: "#fd5949",
        link: "https://www.instagram.com/serioh9",
      },
      {
        icon: faTwitch,
        color: theme.colors.green,
        hoverColor: "#6441a5",
        link: "https://www.twitch.tv/serioh9",
      },
      {
        icon: faYoutube,
        color: theme.colors.green,
        hoverColor: "#ff0000",
        link: "https://www.youtube.com/@SerioH9",
      },
    ],
  },
  {
    image: VladyslavTofetsky,
    name: "Владислав Тофецький",
    biography: "Режисер",
    socialMedia: [
      {
        icon: faInstagram,
        color: theme.colors.green,
        hoverColor: "#fd5949",
        link: "https://www.instagram.com/vladyslav.png",
      },
    ],
  },
];

export const renderTeam = () => {
  return TeamData.map((founder, index) => (
    <FoundersContainer key={index}>
      <StyledImgSection3 src={founder.image} />
      <FoundersTextContainer>
        <FoundersTitle>{founder.name}</FoundersTitle>
        {/* <FoundersTitleBiography>{founder.biography}</FoundersTitleBiography> */}

        <FoundersLinks>
          {founder.socialMedia.map((social, i) => (
            <a key={i} href={social.link}>
              <StyledFontAwesomeIcon
                icon={social.icon}
                color={social.color}
                hoverColor={social.hoverColor}
              />
            </a>
          ))}
        </FoundersLinks>
      </FoundersTextContainer>
    </FoundersContainer>
  ));
};
