import Italy from "../../assets/flags/italy.svg";
import Australia from "../../assets/flags/australia.svg";
import GreatBritain from "../../assets/flags/greatbritain.svg";
import Portugal from "../../assets/flags/portugal.svg";
import SaudiArabia from "../../assets/flags/saudiarabia.svg";
import France from "../../assets/flags/france.svg";
import Germany from "../../assets/flags/germany.svg";
import Bahrain from "../../assets/flags/bahrain.svg";
import Qatar from "../../assets/flags/qatar.svg";
import Spain from "../../assets/flags/spain.svg";
import Hungary from "../../assets/flags/hungary.svg";
import Belgium from "../../assets/flags/belgium.svg";
import Japan from "../../assets/flags/japan.svg";
import China from "../../assets/flags/china.svg";
import UnitedStates from "../../assets/flags/unitedstates.svg";
import Monaco from "../../assets/flags/monaco.svg";
import Canada from "../../assets/flags/canada.svg";
import Austria from "../../assets/flags/austria.svg";
import Netherlands from "../../assets/flags/netherlands.svg";
import Azerbaijan from "../../assets/flags/azerbaijan.svg";
import Singapore from "../../assets/flags/singapore.svg";
import Mexico from "../../assets/flags/mexico.svg";
import Brazil from "../../assets/flags/brazil.svg";
import AbuDhabi from "../../assets/flags/abudhabi.svg";
import Norway from "../../assets/flags/norway.svg";

const flagImages = {
  Italy,
  "Great Britain": GreatBritain,
  Portugal,
  "Saudi Arabia": SaudiArabia,
  France,
  Germany,
  Bahrain,
  Qatar,
  Spain,
  Hungary,
  Belgium,
  Australia,
  Japan,
  China,
  "United States": UnitedStates,
  Monaco,
  Canada,
  Austria,
  Netherlands,
  Azerbaijan,
  Singapore,
  Mexico,
  Brazil,
  "United Arab Emirates": AbuDhabi,
  Norway
};

export const getFlag = (country) => {
  return flagImages[country];
};
