import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SettingsIcon from "@mui/icons-material/Settings";
import RouteIcon from "@mui/icons-material/Route";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

import Skeleton from "@mui/material/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import theme from "../../styles/theme";
import { getFlag } from "../../utils/helpers/flags";

import { faYoutube } from "@fortawesome/free-brands-svg-icons";

const Line = styled.div`
  width: 100%;
  height: 5px;
  margin: auto;
  margin-bottom: 20px;
`;

const ScheduleItem = styled.div`
  height: 100%;
  width: 100%;
  box-shadow: #00000040 0px 2px 8px 0px;
  color: ${theme.colors.white};
  max-width: 420px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${theme.colors.darkLight};

  &.finished {
    // opacity: 0.5;

    ${Line} {
      background-color: ${theme.colors.red};
    }
  }

  &.today {
    ${Line} {
      background-color: ${theme.colors.green};
    }
  }

  &.future {
    ${Line} {
      background-color: ${theme.colors.white};
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  user-select: none;
`;

const ScheduleMainImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
`;

const BottomContainer = styled.div`
  padding: 16px;
  height: 210px;
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Flag = styled.img`
  width: 42px;
  height: 24px;
  object-fit: cover;
`;

const Title = styled.div`
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin-left: 8px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: ${theme.colors.white};
  opacity: 50%;
`;

const Track = styled.div`
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  gap: 3px;
`;

const Text = styled.div`
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: ${theme.colors.white};
  white-space: pre;
  margin-top: 16px;
  margin-bottom: 4px;
`;

const Date = styled.div`
  margin-top: 4px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-left: auto;
`;

const StyledButton = styled(Button)`
  background-color: #ff0000 !important;
`;

const FinishedBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgb(0 0 0);
  opacity: 45%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  padding: 0px 0px 20px 20px;
  color: ${theme.colors.dark};
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;sans-serif;
`;

export const ScheduleItemComponent = ({
  seasonId,
  id,
  name,
  hasResults,
  shortName,
  flag,
  image = "https://cdn.discordapp.com/attachments/1186784392413196419/1192571270659846324/poster_intro.png",
  subtitle,
  date,
  car,
  configuration,
}) => {
  const dateObj = moment(date, "YYYY-MM-DD");
  const dateString = dateObj.format("DD.MM");
  const text = "19:30 Практика \n21:30 Гран-Прі";

  const today = dateObj.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");
  const isFinished = !today && dateObj < moment();
  const className = today ? "today" : isFinished ? "finished" : "future";

  return (
    <ScheduleItem className={className}>
      {isFinished && <FinishedBlock />}
      <ImageContainer>
        <ScheduleMainImage src={image} />
      </ImageContainer>

      <BottomContainer>
        <Line />
        <TitleBlock>
          {flag && <Flag src={getFlag(flag)} />}
          <Title>{shortName || "TBD"}</Title>
          <Date>{dateString}</Date>
        </TitleBlock>
        <Subtitle>{subtitle}</Subtitle>

        <div style={{ marginTop: 12, height: 64 }}>
          <Track>
            <DirectionsCarIcon
              sx={{ fontSize: 14, color: theme.colors.green }}
            />
            <span> {car || "TBD"}</span>
          </Track>
          {name && (
            <Track>
              <RouteIcon sx={{ fontSize: 14, color: theme.colors.green }} />
              <span> {name}</span>
            </Track>
          )}
          {configuration && (
            <Track>
              <SettingsIcon sx={{ fontSize: 14, color: theme.colors.green }} />
              <span> {configuration}</span>
            </Track>
          )}
        </div>
        {!isFinished && <Text>{text}</Text>}
      </BottomContainer>

      {isFinished && hasResults && (
        <StyledLink to={`/results?s=${seasonId}&r=${id}`}>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            sx={{
              fontFamily: "Roboto Condensed",
              marginTop: "12px",
              zIndex: 2,
              opacity: "85%",
            }}
            endIcon={<ArrowRightIcon sx={{}} />}
          >
            Результати
          </Button>
        </StyledLink>
      )}

      {!isFinished && today && (
        <StyledLink
          to="https://www.youtube.com/@slickracingukraine/streams"
          target="_blank"
        >
          <StyledButton
            size="small"
            color="primary"
            variant="contained"
            sx={{
              fontFamily: "Roboto Condensed",
              marginTop: "12px",
            }}
            endIcon={
              <FontAwesomeIcon
                icon={faYoutube}
                className="icon-twitch"
                style={{
                  fontSize: "16px",
                }}
              />
            }
          >
            Youtube
          </StyledButton>
        </StyledLink>
      )}
    </ScheduleItem>
  );
};

export const ScheduleItemSkeleton = () => {
  return (
    <ScheduleItem>
      <ImageContainer>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"
        />
      </ImageContainer>
      <BottomContainer>
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton width="90%" />
        <Skeleton width="60%" />
        <Skeleton width="40%" />
        <Skeleton width="30%" />
        <Skeleton width="70%" />
        <Skeleton width="50%" />
        <Skeleton width="30%" />
      </BottomContainer>
    </ScheduleItem>
  );
};
