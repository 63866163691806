import React, { Fragment } from "react";
import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import msToTime from "../../utils/helpers/msToTime";

import theme from "../../styles/theme";

import intermediate from "../../assets/Tyres/Intermediate.png";
import hard from "../../assets/Tyres/Hard.png";
import soft from "../../assets/Tyres/Soft.png";
import medium from "../../assets/Tyres/Midium.png";
import wet from "../../assets/Tyres/Wet.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const StyledTableCell = styled(TableCell)`
  padding: 12px 16px !important;
  white-space: nowrap;
`;
const AllWidthCell = styled(StyledTableCell)`
  text-align: center !important;
  color: ${theme.colors.grey} !important;
`;
const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.darkLight};
  }
`;

const PositionContainer = styled.div`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
`;

const tyreImages = {
  Soft: soft,
  Medium: medium,
  Hard: hard,
  Inter: intermediate,
  Wet: wet,
};

export const EmptyRowQ1 = ({ qualiType }) => {
  if (qualiType === "short") return;
  return (
    <StyledTableRow>
      <AllWidthCell colSpan={100}>Вибув в К1</AllWidthCell>
    </StyledTableRow>
  );
};

export const EmptyRowQ2 = ({ qualiType }) => {
  if (qualiType === "short") return;
  return (
    <StyledTableRow>
      <AllWidthCell colSpan={100}>Вибув в К2</AllWidthCell>
    </StyledTableRow>
  );
};

export const QualiResultsRow = ({
  finishPosition,
  team,
  driver,
  fastestLap,
  timeText,
}) => {
  return (
    <StyledTableRow sx={{}}>
      <StyledTableCell>{finishPosition}</StyledTableCell>
      <StyledTableCell>
        {`${driver.firstName} ${driver.lastName}`}
      </StyledTableCell>
      <StyledTableCell
        sx={{
          "@media (max-width: 480px)": {
            display: "none",
          },
        }}
      >
        {team?.name}
      </StyledTableCell>

      {fastestLap && <StyledTableCell>{timeText}</StyledTableCell>}
      {!fastestLap && (
        <StyledTableCell
          style={{
            color: `${theme.colors.grey}`,
            textTransform: "uppercase",
          }}
        >
          no time
        </StyledTableCell>
      )}

      <StyledTableCell sx={{ display: "flex" }}>
        {fastestLap?.tyreCompound && (
          <img
            alt="tire"
            src={tyreImages[fastestLap?.tyreCompound]}
            style={{ width: "20px" }}
          />
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export const RaceResultsRow = ({
  finishPosition,
  team,
  driver,
  points,
  gap,
  status,
  stints,
  gridPosition,
  fastestLap,
  isFastestLap,
  penalty,
  laps,
  lapsCount,
}) => {
  const lapped = laps > lapsCount;
  const dnf = status === "DNF";
  const dsq = status === "DSQ";
  console.log({ dnf, dsq, status });
  const finished = !dsq && !dnf;
  const isTime = finished && !lapped;
  const positionChange = gridPosition - finishPosition;
  const positionChangeColor = positionChange > 0 ? "#1CE39F" : "#D07149";
  const fastestLapColor = isFastestLap
    ? "#796FF2 !important"
    : `${theme.colors.white} !important`;
  const penaltySec = penalty.secsInGame + penalty.secsStewards;

  return (
    <StyledTableRow>
      <StyledTableCell>{finishPosition}</StyledTableCell>
      <StyledTableCell
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          alignItems: "center",
          padding: "12px 0px !important",
        }}
      >
        <PositionContainer color={positionChangeColor}>
          {positionChange > 0 && (
            <KeyboardArrowUpIcon sx={{ fontSize: "21px" }} />
          )}
          {positionChange < 0 && (
            <KeyboardArrowDownIcon sx={{ fontSize: "21px" }} />
          )}
          {positionChange !== 0 && Math.abs(positionChange)}
        </PositionContainer>
      </StyledTableCell>
      <StyledTableCell>
        {`${driver.firstName} ${driver.lastName}`}
      </StyledTableCell>
      <StyledTableCell
        sx={{
          "@media (max-width: 480px)": {
            display: "none",
          },
        }}
      >
        {team?.name}
      </StyledTableCell>

      {isTime && (
        <StyledTableCell>
          {finishPosition === 1 ? msToTime(gap) : `+${msToTime(gap)}`}
        </StyledTableCell>
      )}

      {dnf && <StyledTableCell>DNF</StyledTableCell>}

      {!dnf && lapped && (
        <StyledTableCell>+ {laps - lapsCount} Laps </StyledTableCell>
      )}

      <StyledTableCell sx={{ color: "#A26565 !important" }}>
        {!dsq && !dnf && penaltySec > 0 && `+${penaltySec} сек.`}
      </StyledTableCell>

      {stints && (
        <StyledTableCell
          sx={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          {stints?.map((stint, index) => (
            <Fragment key={index}>
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  fontSize: "14px",
                }}
              >
                <img
                  src={tyreImages[stint.tyreCompound]}
                  style={{ width: "20px" }}
                  alt="tyre"
                />
                {stint.laps}
              </div>
            </Fragment>
          ))}
        </StyledTableCell>
      )}

      <StyledTableCell
        sx={{
          color: fastestLapColor,
        }}
      >
        {fastestLap && msToTime(fastestLap.time)}
        {!fastestLap && (
          <div
            style={{
              color: `${theme.colors.grey}`,
              textTransform: "uppercase",
            }}
          >
            no time
          </div>
        )}
      </StyledTableCell>

      <StyledTableCell>{points}</StyledTableCell>
    </StyledTableRow>
  );
};

export const RaceResultsRowSkeleton = () => {
  return (
    <StyledTableRow>
      <StyledTableCell width="4%">
        <Skeleton height="21px" style={{ borderRadius: "12px" }} />
      </StyledTableCell>
      <StyledTableCell width="4%">
        <Skeleton height="21px" style={{ borderRadius: "12px" }} />
      </StyledTableCell>
      <StyledTableCell width="16%">
        <Skeleton height="21px" style={{ borderRadius: "12px" }} />
      </StyledTableCell>
      <StyledTableCell width="16%">
        <Skeleton height="21px" style={{ borderRadius: "12px" }} />
      </StyledTableCell>
      <StyledTableCell width="12%">
        <Skeleton height="21px" style={{ borderRadius: "12px" }} />
      </StyledTableCell>
      <StyledTableCell width="8%">
        <Skeleton height="21px" style={{ borderRadius: "12px" }} />
      </StyledTableCell>
      <StyledTableCell width="22%">
        <Skeleton height="21px" style={{ borderRadius: "12px" }} />
      </StyledTableCell>
      <StyledTableCell width="16%">
        <Skeleton height="21px" style={{ borderRadius: "12px" }} />
      </StyledTableCell>
      <StyledTableCell width="100%">
        <Skeleton height="21px" style={{ borderRadius: "12px" }} />
      </StyledTableCell>
    </StyledTableRow>
  );
};
