const env = process.env.NODE_ENV;
const isDevelopmentMode = process.env.NODE_ENV === "development";
console.log("env: " + env);

const config = {
  apiURL: isDevelopmentMode
    ? "https://heroku.slickracing.com.ua/api/v1"
    : "https://api.slickracing.com.ua/api/v1",
  csmURL: "https://cms.slickracing.com.ua",
  cmsApiToken:
    "8c452d36ce55d37b3833b0ed08621fb301bd2ce2e7220cb9ef1d7a1db049393ddc69eb0a294ac5f3e1f3416fd3d4e6167e8c80cd361f1363f40ba0c83e6cb59f1329299a37633f48e253ed13d71fdd0af442e039c25f4cd4d5de88fe943c563dc45de37713823b1a20867a878e9dfaf2cd53a27279f4989eb2f80e0c7049fe13",
  cmsEventsToken:
    "116c16fc222907326e6623b11018d190f321937676bb2f67346c57670e66f7582c8ec2521917e05054d8c731f3ef77ea290e77f17610615b25ad660713022cd700fad8727dbf559ec70278024eb0978babd30f197f665d34719ed1097cdc269482b3ed561c40879d64db69e5fb31fbe5c09b440bf0725c793f1f83534a98e13b",
  constants: {
    lastSeason: 9,
  },
};

export default config;
